import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import Moment from "moment"
import colors from "../styles/colors"

const footerStyle = css`
  width: 100%;
  background-color: ${colors.background};
  color: ${colors.primary};
  margin: 10px;
  padding: 20px 30px;
  font-size: 12px;
  line-height: 16px;
  box-shadow: 0px 10px 10px 0px ${colors.shadowColor};
`

const linkStyle = css`
  color: ${colors.primary};
`

const subscribeContainer = css`
  box-shadow: 0px 0px 10px 0px ${colors.shadowColor};
  padding: 5px 5px 20px 5px;
  margin-bottom: 20px;
  text-align: center;
`

const inputStyle = css`
  /* height: 25px; */
  width: 200px;
  padding: 5px 10px;
  line-height: 20px;
  border: 1px solid ${colors.secondary};
  outline: none;
  /* border: 1px solid ${colors.primary}; */
`

const subscribeButtonStyle = css`
  height: 34px;
  background-color: ${colors.secondary};
  color: ${colors.primary};
  outline: none;
  border: 1px solid ${colors.secondary};
  /* box-shadow: 3px 3px 1px 0px ${colors.shadowColor}; */
`

const SubscribeHeader = styled("h4")`
  /* border: 1px solid blue; */
  margin-bottom: 0px;
  font-size: 14px;
`
const subscribeText = css`
  /* margin-top: 0px; */
  /* border: 1px solid green; */
`

const updatedText = css`
  font-size: 10px;
  color: ${colors.linkDark};
  text-align: right;
`

const SiteFooter = () => {
  const updatedOn = Moment().format("MMMM Do YYYY")
  return (
    <footer css={footerStyle}>
      <div css={subscribeContainer}>
        <form
          action="https://buttondown.email/api/emails/embed-subscribe/suprada"
          method="post"
          target="popupwindow"
          onSubmit={() =>
            window.open("https://buttondown.email/suprada", "popupwindow")
          }
          className="embeddable-buttondown-form"
        >
          <SubscribeHeader>
            Subscribe to the Year of the Turtle Newsletter:{" "}
          </SubscribeHeader>
          <p css={subscribeText}>
            31 newsletters, every Sunday starting from Jan 5, 2020. Want in?
            Simple unsubscribe if you change your mind.
          </p>
          {/* <label for="bd-email">Enter your email</label> */}
          <input
            css={inputStyle}
            type="email"
            name="email"
            id="bd-email"
            placeholder="Your email here"
          />
          <input type="hidden" value="1" name="embed"></input>
          {/* <input type="submit" value="Subscribe"></input> */}
          <button type="submit" css={subscribeButtonStyle}>
            Subscribe
          </button>
        </form>
      </div>
      <div>
        <p>
          This website is build using{" "}
          <a css={linkStyle} href="https://www.gatsbyjs.org/">
            Gatsby
          </a>
          .{" "}
          <a css={linkStyle} href="https://emotion.sh">
            Emotion
          </a>{" "}
          is used for Css-in-JS styling. The logo is from{" "}
          <a
            css={linkStyle}
            href="https://www.flaticon.com/<?=_('authors/')?>freepik"
          >
            Freepik
          </a>
          , available at{" "}
          <a css={linkStyle} href="https://www.flaticon.com/">
            https://flaticon.com/
          </a>
          .
        </p>
        <p>
          The fonts used are{" "}
          <a
            href="https://djr.com/notes/zenith-djr-font-of-the-month/"
            css={linkStyle}
          >
            Zenith DJR{" "}
          </a>{" "}
          for display texts, and{" "}
          <a
            href="https://djr.com/notes/roslindale-text-font-of-the-month/"
            css={linkStyle}
          >
            Roslindale Text
          </a>{" "}
          for body. The fonts used here are available at{" "}
          <a css={linkStyle} href="https://fontofthemonth.club/">
            Font of the Month Club
          </a>
          . Each post may have a different font each month.
        </p>
        <p>Questions? Typos! Talk to me? Email suprada@suprada.com</p>
        <p css={updatedText}>Updated: {updatedOn}</p>
      </div>
    </footer>
  )
}

export default SiteFooter
