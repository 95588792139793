import React from "react"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import Helmet from "react-helmet"
import GlobalStyles from "../styles/globalStyles"
import SiteHeader from "./siteHeader"
import colors from "../styles/colors"
import config from "../../data/SiteConfig"
import SiteFooter from "./siteFooter"

const Wrapper = styled("div")`
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const ContentWrapper = styled("div")`
  margin: 10px;
  padding: 20px 20px 40px 20px;
  max-width: 600px;
  min-width: 200px;
  min-height: 500px;
  background-color: ${colors.background};
  flex: 1 1 auto;
  box-shadow: 6px 6px 10px 0px ${colors.shadowColor};
`

const Layout = ({ children }) => (
  <Wrapper>
    <Global styles={GlobalStyles} />
    <Helmet>
      <meta name="description" content={config.siteDescription} />
      <html lang="en" />
    </Helmet>
    <SiteHeader />
    <ContentWrapper>{children}</ContentWrapper>
    <SiteFooter />
  </Wrapper>
)

export default Layout
