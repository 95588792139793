import { css } from "@emotion/core"
import woff from "./GimletXRay-VF.woff"
import woff2 from "./GimletXRay-VF.woff2"

const GimletXRay = css`
  @font-face {
    src: url(${woff2}) format("woff2"); /* IE < 9 */
    src: url(${woff}) format("woff");
    font-family: "Gimlet XRay Web";
    font-weight: 300 900;
    font-stretch: 64 100;
  }
`

export default GimletXRay
