import { css } from "@emotion/core"
import woff from "./RoslindaleVariableDeck-VF.woff"
import woff2 from "./RoslindaleVariableDeck-VF.woff2"

const RoslindaleDeckWeb = css`
  @font-face {
    src: url(${woff2}) format("woff2"); /* IE < 9 */
    src: url(${woff}) format("woff");
    font-family: "Roslindale Deck Web";
    font-style: normal;
    font-weight: 400 700;
  }
`

export default RoslindaleDeckWeb
