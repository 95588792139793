import { css } from "@emotion/core"
import woff from "./DattiloDJRBannerVariable-VF.woff"
import woff2 from "./DattiloDJRBannerVariable-VF.woff2"

const DattiloDJRBanner = css`
  @font-face {
    src: url(${woff2}) format("woff2"); /* IE < 9 */
    src: url(${woff}) format("woff");
    font-family: "Dattilo DJR Banner Web";
    font-style: normal;
    font-weight: 400 900;
  }
`
export default DattiloDJRBanner
