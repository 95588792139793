const config = {
  siteTitle: "Year of the Turtle",
  siteTitleShort: "Year of the Turtle",
  siteTitleAlt: "2020 Workspace",
  siteLogo: "/logos/zoo.png",
  siteUrl: "https://suprada.com/year-of-the-turtle",
  pathPrefix: "/",
  siteDescription: "Suprada's workspace for 2020",
  themeColor: "#0b5453",
  backgroundColor: "#f5faf9", // Used for setting manifest background color.
}

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = ""
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1)

module.exports = config
