import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import HeaderLogo from "../../static/logos/zoo.svg"
import colors from "../styles/colors"
import Menu from "./menu"

const HeaderWrapper = styled("div")`
  width: 300px;
  font-family: ZenithSlabDJR, sans-serif;
  display: flex;
  flex-direction: column;
  margin: 10px;
  @media only screen and (max-width: 1097px) {
    width: 600px;
    min-width: 300px;
  }
`

const HeaderLogoContainer = styled("div")`
  background-color: ${colors.secondary};
  display: flex;
  flex-direction: row;
  padding: 20px;
  /* align-items: center; */
  /* justify-content: center; */
  box-shadow: 0px 6px 7px 0px ${colors.shadowColor};
`

const HeaderLogoWithStyle = ({ className }) => (
  <HeaderLogo className={className} />
)

const HeaderLogoStyle = css`
  width: 55px;
  height: 55px;
  margin: 5px;
  fill: ${colors.primary};
`

const HeaderTextStyle = css`
  width: 120px;
  margin: 5px;
  font-size: 18px;
  text-decoration: none;
  color: ${colors.primary};
`

const SiteHeader = () => (
  <HeaderWrapper>
    <HeaderLogoContainer>
      <HeaderLogo css={HeaderLogoStyle} />
      <Link to="/" css={HeaderTextStyle}>
        Year of the Turtle
      </Link>
    </HeaderLogoContainer>
    <Menu />
  </HeaderWrapper>
)

export default SiteHeader
