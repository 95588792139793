module.exports = {
  primary: "#0b5453",
  secondary: "#caf8f1",
  background: "#f5faf9",
  darkOrig: "#000715",
  dark: "#3e3e40",
  link: "#e66f39",
  linkDark: "#b3562d",
  white: "#ffffff",
  teal: "#38a7bd",
  shadowColor: "#cde2ea",
  // unused
  light: "#9ed5dc",
  gray: "#4f4c47",
  lightBlue: "#aaecfc",
  muddyYellow: "#d9ca63",
  brown: "#b87f5c",
  color1: "#e5d571",
  color3: "#38e3b5",
  color4: "#33a58c",
  color6: "#56f7e9",
  color7: "#c1abab",
  color8: "#cde2ea",
  color9: "#a3a291",
  color10: "#ccc",
  color11: "#f0a343",
}

// https://dribbble.com/shots/6451729-Nautilus
// https://dribbble.com/shots/6754324-Aquaworld-Compositing-In-Photoshop/attachments
