import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import colors from "../styles/colors"

const MenuContainer = styled("ul")`
  background-color: ${colors.secondary};
  padding: 20px;
  align-items: center;
  list-style-type: none;
  box-shadow: 0px 8px 7px 0px ${colors.shadowColor};
`

const MenuLinkContainer = styled("li")`
  padding: 5px;
  font-size: 14px;
  font-family: RoslindaleText, serif;
`

const menuLink = css`
  color: ${colors.primary};
  &:hover {
    color: ${colors.color4};
  }

  padding-left: 30px;
`

const Menu = () => (
  <MenuContainer>
    <MenuLinkContainer>
      <a href="https://suprada.com" css={menuLink}>
        suprada.com (2020)
      </a>
    </MenuLinkContainer>
    <MenuLinkContainer>
      <a href="https://booknotes.suprada.com" css={menuLink}>
        booknotes
      </a>
    </MenuLinkContainer>
    <MenuLinkContainer>
      <a href="https://bento.suprada.com" css={menuLink}>
        bento (2020)
      </a>
    </MenuLinkContainer>
    <MenuLinkContainer>
      <a href="https://blog.suprada.com" css={menuLink}>
        blog
      </a>
    </MenuLinkContainer>
  </MenuContainer>
)

export default Menu
