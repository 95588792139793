import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import colors from "../styles/colors"

const PostLink = styled(Link)`
  color: ${colors.dark};
`

const PostTitle = styled("h3")`
  font-size: 16px;
  font-weight: 300;
`

const getPostList = ({ postEdges }) => {
  const postList = []
  postEdges.forEach(postEdge => {
    postList.push({
      path: postEdge.node.fields.slug,
      tags: postEdge.node.frontmatter.tags,
      cover: postEdge.node.frontmatter.cover,
      title: postEdge.node.frontmatter.title,
      date: postEdge.node.fields.date,
      excerpt: postEdge.node.excerpt,
      timeToRead: postEdge.node.timeToRead,
    })
  })
  return postList
}

const PostListing = props => {
  const { postEdges } = props
  const postList = getPostList({ postEdges })
  return (
    <div>
      {/* Your post list here. */
      postList.map(post => (
        <PostLink to={post.path} key={post.title}>
          <PostTitle>{post.title}</PostTitle>
        </PostLink>
      ))}
    </div>
  )
}

export default PostListing
