import { css } from "@emotion/core"
import RoslindaleTextFont from "../fonts/RoslindaleText/RoslindaleTextFont"
import ZenithSlabFont from "../fonts/ZenithSlab/ZenithSlabFont"
import GimletXRay from "../fonts/GimletXRay/GimletXRay"
import DattiloDJRBanner from "../fonts/DattiloDJRBanner/DattiloDJRBanner"
import RoslindaleDeckWeb from "../fonts/RoslindaleDeckWeb/RoslindaleDeckWeb"
import backgroundPattern from "../images/pattern19.png"
import colors from "../styles/colors"

const MonthlyStyles = css`
  .feb.newsletter {
    font-family: GimletXRay, serif;
    font-size: 18px;
  }
  .feb.newsletter-title {
    font-family: GimletXRay, serif;
    font-size: 30px;
    font-weight: 600;
  }
  .mar.newsletter-title {
    font-family: "Dattilo DJR Banner Web", serif;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .apr.newsletter-title {
    font-family: "Dattilo DJR Banner Web", serif;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .apr.newsletter {
    font-family: RoslindaleText, serif;
    font-size: 16px;
  }
`

const GlobalStyles = css`
  ${ZenithSlabFont}
  ${RoslindaleTextFont}
  ${RoslindaleDeckWeb}
  html, body {
    font-family: RoslindaleText, serif;
    font-weight: regular;
    font-size: 16px;
    background: url(${backgroundPattern});
    color: ${colors.dark};
  }
  h1 {
    font-family: ZenithSlabDJRColorSVG;
    font-weight: normal;
  }
  blockquote {
    font-size: 1.1rem;
    border-left: 10px solid ${colors.color10};
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    color: ${colors.gray};
    font-family: "Roslindale Deck Web";
    font-weight: 400;
  }
  blockquote:before {
    color: ${colors.color10};
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  .newsletter {
    font-family: RoslindaleText, serif;
    font-weight: 100;
    font-size: 16px;
    line-height: 23px;
    color: ${colors.darkLight};
  }
  ${MonthlyStyles}
  .fancy-quote {
    background: ${colors.dark};
    padding: 0px 3px;
  }

  .fancy-quote blockquote {
    color: ${colors.background};
    font-size: 28px;
    line-height: 42px;
    border-left: none;
    font-weight: 400;
  }

  .fancy-quote blockquote:before {
    content: none;
  }

  .quote-author {
    text-align: right;
  }

  .two-column-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .first-column-list{
    width: 40%;
  }
  .second-column-list{
    width: 40%;
  }
`

export default GlobalStyles
