import { css } from "@emotion/core"
import regularEot from "./RoslindaleText-Regular.eot"
import regularWoff from "./RoslindaleText-Regular.woff"
import regularWoff2 from "./RoslindaleText-Regular.woff2"
import boldEot from "./RoslindaleText-Bold.eot"
import boldWoff from "./RoslindaleText-Bold.woff"
import boldWoff2 from "./RoslindaleText-Bold.woff2"
import italicEot from "./RoslindaleText-Italic.eot"
import italicWoff from "./RoslindaleText-Italic.woff"
import italicWoff2 from "./RoslindaleText-Italic.woff2"

const RoslindaleTextFont = css`
  @font-face {
    src: url(${regularEot}); /* IE < 9 */
    src: url(${regularEot}#) format("embedded-opentype"),
      /* IE 9 */ url(${regularWoff2}) format("woff2"),
      url(${regularWoff}) format("woff");
    font-family: "RoslindaleText";
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    src: url(${boldEot}); /* IE < 9 */
    src: url(${boldEot}#) format("embedded-opentype"),
      /* IE 9 */ url(${boldWoff2}) format("woff2"),
      url(${boldWoff}) format("woff");
    font-family: "RoslindaleText";
    font-style: normal;
    font-weight: bold;
  }

  @font-face {
    src: url(${italicEot}); /* IE < 9 */
    src: url(${italicEot}#) format("embedded-opentype"),
      /* IE 9 */ url(${italicWoff2}) format("woff2"),
      url(${italicWoff}) format("woff");
    font-family: "RoslindaleText";
    font-style: italic;
    font-weight: normal;
  }
`

export default RoslindaleTextFont
