import { css } from "@emotion/core"

import BoldWoff from "./ZenithSlabDJR-Bold.woff"
import BoldWoff2 from "./ZenithSlabDJR-Bold.woff2"
import RegularWoff from "./ZenithSlabDJR-Regular.woff"
import RegularWoff2 from "./ZenithSlabDJR-Regular.woff2"
import ColorRegularWoff from "./ZenithSlabDJRColor-Regular.woff"
import ColorRegularWoff2 from "./ZenithSlabDJRColor-Regular.woff2"
import SvgWoff from "./ZenithSlabDJRColorSVG-Regular.woff"
import SvgWoff2 from "./ZenithSlabDJRColorSVG-Regular.woff2"

const ZenithSlabFont = css`
  @font-face {
    src: url(${BoldWoff});
    src: url(${BoldWoff2}) format("woff2"), url(${BoldWoff}) format("woff");
    font-family: "ZenithSlabDJR";
    font-style: normal;
    font-weight: bold;
  }

  @font-face {
    src: url(${RegularWoff});
    src: url(${RegularWoff2}) format("woff2"),
      url(${RegularWoff}) format("woff");
    font-family: "ZenithSlabDJR";
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    src: url(${ColorRegularWoff});
    src: url(${ColorRegularWoff2}) format("woff2"),
      url(${ColorRegularWoff}) format("woff");
    font-family: "ZenithSlabDJRColor";
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    src: url(${SvgWoff});
    src: url(${SvgWoff2}) format("woff2"), url(${SvgWoff}) format("woff");
    font-family: "ZenithSlabDJRColorSVG";
    font-style: normal;
    font-weight: normal;
  }
`
export default ZenithSlabFont
